import React, { Fragment } from "react"

/**
 * Change the content to add your own bio
 */

export default () => (
  <Fragment>
    A collection of some small code snippets & experiments
    <br />
    No tutorials. Nothing fancy. Just some code.
  </Fragment>
)
